import { useEffect, useMemo, useState, useCallback } from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import { Container, Snackbar, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from './candy-machine';
import { AlertState } from './utils';
import { Header } from './Header';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import { isTemplateHead } from 'typescript';

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(async () => {
    if (!anchorWallet) {
      return;
    }

    if (props.candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection,
        );
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [anchorWallet, props.candyMachineId, props.connection]);

  const onMint = async () => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey)
        )[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            true,
          );
        }

        if (status && !status.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          });
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
    } finally {
      setIsUserMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  return (
   <div> 
      <div style={{ backgroundColor: "white", borderBottom: "1px #ccc solid" }}>
        <Container style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Grid container style={{lineHeight: "41px"}}>
            <Grid item xs={8}>
              <div>
                <img style={{height: "41px", verticalAlign:"middle", borderRadius: "10px", border: "1px #777 solid"}} src="logo192.png"></img>&nbsp;&nbsp;
                <img style={{verticalAlign:"middle"}} width="190px" src="carolinedao-logo.png"></img></div>
            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
              <a target="_blank" href="https://discord.gg/4rkg2N6nzP" style={{ verticalAlign:"middle", display: "inline-block", height: "41px", lineHeight: "41px", borderRadius: "8px", textDecoration: "none", color: "white", fontWeight: "bold", backgroundColor: "#1F4E79", paddingLeft: "20px", paddingRight: "20px"}}>
              <img style={{verticalAlign:"middle"}} height="16px" src="discord.svg"></img>&nbsp;&nbsp;
              Join Us
              </a>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{backgroundColor: "#f0ffff", borderBottom: "1px #ccc solid"}}>
        <div style={{textAlign: "center", paddingTop: "80px"}}>
        <img src="carolinedao-logo.png"></img>
        <h4 style={{fontSize: "30px", padding: "0px", margin: "0px", paddingTop: "20px"}}>Sophisticated, Intelligent, Mathematical, Precise</h4>
        <br></br>
        <br></br>
        
        <div style={{fontWeight: "bold", fontSize: "20px", color: "#603D6B"}}>Join and you might avoid liquidation in the next cycle.</div>
        <br></br>

        <img height="300px" src="caroline-large.png"></img>
        <br></br>
        <br></br>
        <br></br>
        
        </div>

      </div>

      <div>

      <div id="mint"></div>
      <Container maxWidth="xs" style={{ paddingTop: "30px", paddingBottom: "200px", position: 'relative' }}>
        <div style={{textAlign: "center", fontWeight: "bold", fontSize: "60px", marginBottom: "20px"}}>Mint</div>
        <Paper
          style={{ padding: 24, backgroundColor: '#151A1F', borderRadius: 6 }}
        >
          {!wallet.connected ? (
            <ConnectButton>Connect Wallet</ConnectButton>
          ) : (
            <>
              <Header candyMachine={candyMachine} />
              <MintContainer>
                {candyMachine?.state.isActive &&
                  candyMachine?.state.gatekeeper &&
                  wallet.publicKey &&
                  wallet.signTransaction ? (
                  <GatewayProvider
                    wallet={{
                      publicKey: wallet.publicKey ||
                        new PublicKey(CANDY_MACHINE_PROGRAM),
                      signTransaction: wallet.signTransaction,
                    }}
                    gatekeeperNetwork={candyMachine?.state?.gatekeeper?.gatekeeperNetwork}
                    clusterUrl={rpcUrl}
                    options={{ autoShowModal: false }}
                  >
                    <MintButton
                      candyMachine={candyMachine}
                      isMinting={isUserMinting}
                      onMint={onMint} />
                  </GatewayProvider>
                ) : (
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    onMint={onMint} />
                )}
              </MintContainer>
            </>
          )}
        </Paper>
      </Container>
      <Snackbar open={alertState.open} autoHideDuration={6000} onClose={() => setAlertState({ ...alertState, open: false })}>
        <Alert onClose={() => setAlertState({ ...alertState, open: false })} severity={alertState.severity}>
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
    <div style={{ backgroundColor: "#333" }}>
      <Container style={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <Grid container style={{lineHeight: "41px"}}>
            <Grid item xs={8} style={{color: "white"}}>
            © 2022 CarolineDAO
            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
              <a target="_blank" href="https://discord.gg/4rkg2N6nzP"><img style={{verticalAlign:"middle"}} height="30px" src="discord.svg"></img></a>
            </Grid>
          </Grid>
      </Container>
    </div>
  </div>
  );
};

export default Home;
